import React, { Component } from 'react';

class Portfolio extends Component {
  render() {
    return (
      <div class="pricing-wrapper clearfix" id="portfolio">        
        <div class="pricing-table">
          <h3 class="pricing-title">Consultation d'allaitement</h3>
          <ul class="table-list">
            <li>
              1h à 1h15<span> la séance</span>
            </li>
          </ul>
          <div class="table-buy">
            <p>
              65€<sup>/ consultation</sup>
            </p>
          </div>
          <p>Zone 1</p>
        </div>  
        <div class="pricing-table">
          <h3 class="pricing-title">Consultation d'allaitement</h3>
          <ul class="table-list">
            <li>
              1h à 1h15<span> la séance</span>
            </li>
          </ul>
          <div class="table-buy">
            <p>
              75€<sup>/ consultation</sup>
            </p>
          </div>
          <p>Zone 2</p>
        </div>      

        <div class="pricing-table">
          <h3 class="pricing-title">Consultation en puériculture</h3>
          <ul class="table-list">
            <li>
              1h <span> de consultation</span>
            </li>
          </ul>

          <div class="table-buy">
            <p>
              60€<sup>/ séance </sup>
            </p>
          </div>
          <p>Au domicile des parents</p>
        </div>

        <div class="pricing-table">
          <h3 class="pricing-title">Atelier de portage individuel</h3>
          <ul class="table-list">
            <li>
              2h~2h30<span> la séance</span>
            </li>
          </ul>

          <div class="table-buy">
            <p>
              65€<sup>/ séance </sup>
            </p>
          </div>
          <p>Au domicile des parents</p>
        </div>

        <div class="pricing-table recommended">
          <h3 class="pricing-title">Atelier de portage en groupe</h3>
          <ul class="table-list">
            <li>
              2h~2h30 <span>la séance</span>
            </li>
          </ul>
          <div class="table-buy">
            <p>
              40€<sup>/ personne</sup>
            </p>
          </div>
          <p>Pôle Médical de Marne-La-Vallée</p>
        </div>
        <div class="pricing-table recommended">
          <h3 class="pricing-title">
            Atelier <br />
            Massage <br /> bébé
          </h3>
          <ul class="table-list">
            <li>
              45 min à 1h <span>la séance</span>
            </li>
          </ul>
          <div class="table-buy">
            <p>
              40€<sup>la séance découverte</sup>
            </p>
          </div>
          <p>
            150€ <span>/ 5 séances</span>
          </p>
        </div>

        {/* <div class="pricing-table">
          <h3 class="pricing-title">
            Pack <br /> 2 ateliers <br />
            de portage
          </h3>
          <ul class="table-list">
            <li>
              2h~2h30 <span>la séance</span>
            </li>
          </ul>
          <div class="table-buy">
            <p>
              90€<sup>en atelier individuel </sup>
            </p>
          </div>
          <p>
            80€ <span>/ personne en atelier de groupe</span>
          </p>
        </div> */}
      </div>
    );


  }
}

export default Portfolio;
