import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var bio1 = this.props.data.bio1;
      var bio2 = this.props.data.bio2;
      var bio3 = this.props.data.bio3;
      var bio4 = this.props.data.bio4;
      var bio5 = this.props.data.bio5;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <picture>
              <source srcset={profilepic} type="image/webp" />
              <source srcset={profilepic} type="image/jpeg" />
            </picture>
            {/* <img
              className="profile-pic"
              src={profilepic}
              alt="Cécile puéricultrice"
            /> */}
          </div>
          <div className="nine columns main-col">
            <h2>A propos</h2>

            <p>{bio}</p>
            <p>{bio1}</p>
            <p>{bio2}</p>
            <p>
              {bio3} <a href="https://cnfpb.fr">(CNFPB)</a>.
            </p>
            <p>{bio4}</p>
            <p>{bio5}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact</h2>
                <p className="address">
                  <span>{name}</span>
                  <br />
                  <span>
                    {street}
                    <br />
                    {city}
                    <br />
                    {zip}
                  </span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
              {/*<div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>QR CODE ET FAcebook</a>
                  </p>
               </div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
