import React, { Component } from "react";

class Resume extends Component {
  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    //<p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        var image = "images/" + education.img;
        if (Array.isArray(education.description1)) {
          return (
            <div key={education.school}>
              <h3>{education.school}</h3>
              <p>
                {education.description1.map(function (item, i) {
                  return <p>{education.description1[i]}</p>;
                }, this)}
              </p>
              <center>
                <img src={image} />
              </center>
            </div>
          );
        }
        return (
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p>{education.description1}</p>
            <p>{education.description2}</p>
            <p>{education.description3}</p>
            <center>
              <img src={image} />
            </center>
          </div>
        );
      });
      var work = this.props.data.work.map(function (work) {
        return (
          <div key={work.company}>
            <h3>{work.company}</h3>
            <p className="info">
              {work.title}
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <p>{work.description}</p>
          </div>
        );
      });

      var skills = this.props.data.skills.map((skills) => {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span
              style={{
                width: skills.level,
                backgroundColor: this.getRandomColor(),
              }}
              className={className}
            ></span>
            <em>{skills.name}</em>
          </li>
        );
      });
    }

    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Prestations</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{education}</div> 
            </div>
          </div>
        </div>
        <div >        
        <div id="go-resa">
          <h3>
            <a title="Réservez" href="https://booking.myrezapp.com/fr/online/booking/minisite/16343/cecile-puericultrice">Réservation</a>
            </h3>
        </div>  
        </div>
        
      </section>
    );
  }
}

export default Resume;
